import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import {QuillFormattingSizeComponent} from "../size/QuillFormattingSizeComponent";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {Resources} from "../../../../../../../../resources/Resources";

export class QuillFormattingButtonFontSizeComponent extends QuillFormattingBaseButtonComponent {
    private BASE_FONTSIZE: string = "14px";

    constructor(formatting: QuillFormattingComponent, name: string) {
        super(name, formatting);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton} ${s.large}">
                <div class="${s.icon} ${s.fontSize}"></div>
                <div class="${s.icon} ${s.bottom}"></div>
            </button>
        `;
    }

    bind() {
        this.onClick(() => {
            let formats = this.formattingComponent.quillComponent.currentFormats();
            let entries = this.name == "header" ? [
                {size: "2", tag: "h2", name: Resources.t("words.header2")},
                {size: "3", tag: "h3", name: Resources.t("words.header3")},
                {size: "4", tag: "h4", name: Resources.t("words.header4")},
                {size: "5", tag: "h5", name: Resources.t("words.header5")},
                {size: "6", tag: "h6", name: Resources.t("words.header6")},
                {size: "false", tag: "p", name: Resources.t("words.normal")}
            ] : [
                {size: "2em", tag: "h1", name: Resources.t("words.large")},
                {size: "1.5em", tag: "h2", name: Resources.t("words.big")},
                {size: "1.17em", tag: "h3", name: Resources.t("words.medium")},
                {size: "false", tag: "p", name: Resources.t("words.normal")}
            ];

            let tooltipComponent = new QuillFormattingSizeComponent(this, formats[this.name], entries);
            tooltipComponent.screen(false);
            tooltipComponent.create();
            tooltipComponent.onPick = (size) => {
                tooltipComponent.dispose();

                this.format(size == "false" ? false : size);
            }
        });
    }

    isBaseValue(value: string): boolean {
        return value == this.BASE_FONTSIZE;
    }
}