import {
    StaticComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-router/static/component/StaticComponent";
import * as s from "./mobile-navigation.scss";
import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {Network} from "../../../../../network/Network";
import {EntityService} from "../../../../../services/entity/EntityService";
import {Resources} from "../../../../../resources/Resources";
import {ProductName} from "../../../../../models/product/ProductName";
import {ProductType} from "../../../../../models/product/ProductType";
import {MobileHeaderAvatarComponent} from "../../components/header/avatar/MobileHeaderAvatarComponent";

export class MobileNavigationStatic extends StaticComponent {

    public navigationContainer: Component;
    public inboxCase: Component;
    public inboxCaseNotification: Component;

    public rendered: boolean = false;

    constructor() {
        super();
        this.virtual(s.staticMobileNavigation);
    }

    init(): void {
        if (!this.rendered) {
            //language=HTML
            let template = `
                <div class="${s.navigation}">
                    <a href="/b">
                        <div class="${s.case} ${s.home}">
                            <div class="${s.iconContainer}">
                                <div class="${s.icon}"></div>
                            </div>
                            <div class="${s.name}">
                                ${Resources.t(`words.menu`)}
                            </div>
                        </div>
                    </a>
                        <!--
                    <div class="${s.case}">
                        <div class="${s.entity}">
                            <div class="${s.avatar}">
                                {this.draw(new MobileHeaderAvatarComponent())}
                            </div>
                        </div>
                    </div>
                    -->
                        <!--
                    <a href="/general">
                        <div class="${s.case} ${s.home}">
                            <div class="${s.iconContainer}">
                                <div class="${s.icon}"></div>
                            </div>
                            <div class="${s.name}">
                                Home
                            </div>
                        </div>
                    </a>
                    -->
                        <!--
                    <a href="/inbox">
                        <div class="${s.case} ${s.inbox}">
                            <div class="${s.iconContainer}">
                                <div class="${s.icon}"></div>
                            </div>
                            <div class="${s.name}">
                                Inbox
                            </div>
                        </div>
                    </a>
                    -->
                        <!--
                    <a href="/test">
                        <div class="${s.case} ${s.publisher}">
                            <div class="${s.iconContainer}">
                                <div class="${s.icon}"></div>
                            </div>
                            <div class="${s.name}">
                                Publisher
                            </div>
                        </div>
                    </a>
                    <div class="${s.case} ${s.statistics}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.name}">
                            Statistics
                        </div>
                    </div>
                    <div class="${s.case} ${s.contacts}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.name}">
                            Contacts
                        </div>
                    </div>
                    -->
                </div>
            `;

            this.append(template);
            this.rendered = true;
            super.init();
        }

        this.displayShow();
    }

    commit() {
        this.inboxCase = this.el(s.inbox);
        this.navigationContainer = this.el(s.navigation);
        this.renderNotifications();
        super.commit();
    }

    /**
     * rendering
     */
    renderNotifications() {
        this.onReactiveObserve(() => {
            if (this.inboxCaseNotification != undefined) {
                this.inboxCaseNotification.remove();
                this.inboxCaseNotification = undefined;
            }

            if (EntityService.activeNotifications.inboxCount > 0) {
                //language=HTML
                this.inboxCaseNotification = this.inboxCase.append(`
                    <div class="${s.notification}">
                        ${EntityService.activeNotifications.inboxCount}
                    </div>
                `);
            }
        });
    }

    onHash() {
        if (this.element instanceof HTMLElement) {
            let links = this.element.getElementsByTagName("a");
            for (let link of links) {
                let path = link.getAttribute("href").replace("/", "");
                let tabCase = this.ele(link.getElementsByClassName(s.case)[0] as HTMLElement);

                let currentPath = Network.router.pages.getPathWithoutLanguage().split("/")[1];
                if (path.includes("/")) {
                    currentPath = Network.router.pages.getPathWithoutLanguage().substring(1);
                }

                if (path == currentPath) {
                    tabCase.addClass(s.selected);
                } else {
                    tabCase.removeClass(s.selected);
                }
            }
        }
        super.onHash();
    }
}