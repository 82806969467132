import {IGPTMessageModel} from "../../models/gpt/IGPTMessageModel";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {Services} from "../Services";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {ProductType} from "../../models/product/ProductType";

export class GPTService {
    public static segmentEvents: Map<INetworkComponent, (message: IGPTMessageModel) => void> = new Map();

    public static dispose(): void {
        this.segmentEvents.clear();
    }

    public static init(): void {
        Services.beforeInit(this);

        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.GPT_SEGMENT, (message) => {
            this.segmentEvent(message);
        });
    }

    public static segmentEvent(message: IGPTMessageModel) {
        this.segmentEvents.forEach((func, component) => func(message));
    }

    public static onSegment(func: (message: IGPTMessageModel) => void, component: INetworkComponent) {
        this.segmentEvents.set(component, func);
        component.onRemove(() => this.segmentEvents.delete(component));
    }
}