import Quill from 'quill';

let Header = Quill.import('formats/header');

export class QuillHeader extends Header {

    static create(value) {
        let level: number;
        let id: string;

        if (value == '1' || value == '2' || value == '3' || value == '4' || value == '5' || value == '6') {
            level = value;
        } else if (typeof value === 'object' && value !== null) {
            level = value.level || 2;
            id = value.id;
        } else {
            level = 2;
        }

        const node = document.createElement(`H${level}`);
        if (id) {
            node.setAttribute('id', id);
        }
        return node;
    }

    static formats(domNode) {
        let id = domNode.getAttribute('id');
        if (id == null && domNode.innerText?.trim().length > 0) {
            id = domNode.innerText;
            try {
                id = id.normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, '-')
                    .replace(/-+/g, '-')
                    .replace(/^-|-$/g, '');
            } catch (exception) {
                console.error(exception);
            }
            domNode.setAttribute("id", id);
        }
        const level = parseInt(domNode.tagName[1], 10);
        return {level, id};
    }
}

QuillHeader.blotName = 'header';
QuillHeader.tagName = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];